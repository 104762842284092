import { Injectable } from "@angular/core";
import { DataBaseService } from "./data-base.service";
import { HttpClient } from "@angular/common/http";
import { catchError, map, share, tap } from "rxjs/operators";

import { MainProductDto } from "../model/MainProductDto";

import { Subject, Observable } from 'rxjs';
import { CustomerDto } from "../model/CustomerDto";
import { CreateQuoteRequest } from "../model/CreateQuoteRequest";
//import { CreateQuoteResponse } from "../model/CreateQuoteResponse";
import { CreateOrderRequest } from "../model/CreateOrderRequest";
import { CreateOrderResponse } from "../model/CreateOrderResponse";

import { PaymentType } from "../model/PaymentType";
import { ProductConfigOptionDto } from "../model/ProductConfigOptionDto";
import { OfferResponse } from "../model/OfferResponse";
import { QuoteDto } from "../model/QuoteDto";
import { ProductConfigurationDto } from "../model/ProductConfigurationDto";


@Injectable()
export class OfferService extends DataBaseService {

    private ngUnsubscribe: Subject<void> = new Subject();

    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    async createQuoteFromProduct(customer: CustomerDto, product: MainProductDto, configOptions: ProductConfigOptionDto[], productConfiguration: ProductConfigurationDto) {

        let quoteRequest: CreateQuoteRequest = {
            Customer: customer,
            Product: product,
            ProductConfigOptions: configOptions,
            ProductConfig: productConfiguration
        }

        let url = this.apiEndpoint + '/quotes';

        let resultPromise = this.httpClient.post<OfferResponse<QuoteDto>>(url, quoteRequest).toPromise();

        return resultPromise;
    }

//    async createOrderFromProduct(customer: CustomerDto, product: MainProductDto, configOptions: ProductConfigOptionDto[], productConfiguration: ProductConfigurationDto, paymentType: PaymentType) {
    async createOrderFromProduct(orderRequest: CreateOrderRequest) {

        let url = this.apiEndpoint + '/orders';
        let resultPromise = this.httpClient.post<CreateOrderResponse>(url, orderRequest).toPromise();

        return resultPromise;
    }

    async getPdfFromQuote(quoteId: string) {

        let url = this.apiEndpoint + '/documents/quote/' + quoteId;
        let resultPromise = this.httpClient.get(url, { responseType: 'text' }).toPromise();

        return resultPromise;
    }

    async getPdfFromOrder(orderId: string) {

        let url = this.apiEndpoint + '/documents/order/' + orderId;
        let resultPromise = this.httpClient.get(url, { responseType: 'text' }).toPromise();

        return resultPromise;
    }


    createPaypalPayment(orderId: string) {

        let url = this.apiEndpoint + '/payments/create/' + orderId;
        let result = this.httpClient.post(url, null, { responseType: 'text' });

        return result;
    }

    executePaypalPayment(orderId: string, payerId: string, paymentId: string) {

        let url = this.apiEndpoint + '/payments/execute/' + orderId;
        const executePaymentRequest = {
            PayerId: payerId,
            PaymentId: paymentId
        };

        let result = this.httpClient.post(url, executePaymentRequest, { responseType: 'text' });

        return result;
    }

    createPayPalOrder(orderId: string) {

        let url = this.apiEndpoint + '/paypal/order/create/' + orderId;
        let obs = this.httpClient.post(url, null, { responseType: 'text' });

        return obs;
    }

    capturePayPalOrder(orderId: string, payerId: string, payPalOrderId: string) {

        let url = this.apiEndpoint + '/paypal/order/capture/' + orderId;
        const executePaymentRequest = {
            PayerId: payerId,
            OrderId: payPalOrderId
        };

        let obs = this.httpClient.post(url, executePaymentRequest, { responseType: 'text' });
        
        return obs;
    }

    getOffer<T>(type: string, id: string): any {

        let url = `${this.apiEndpoint}/${type}/${id}`;
        let resultPromise = this.httpClient.get<T>(url).toPromise();

        return resultPromise;
    }

    getOfferObs<T>(type: string, id: string, isLoanOffer: boolean = false): Observable<T>  {

        let url = `${this.apiEndpoint}/${type}/${isLoanOffer ? "loan/" : ""}${id}`;
        let resultObs = this.httpClient.get<T>(url).pipe(
            map(response => this.convertDateStringsToDates(response)),
            share()
        );

        return resultObs;
    }

    
    getOffers<T>(type: string): any {

        let url = `${this.apiEndpoint}/${type}`;
        let resultPromise = this.httpClient.get<T>(url).toPromise();

        return resultPromise;
    }

    getOffersObs<T>(type: string): Observable<T> {

        let url = `${this.apiEndpoint}/${type}`;
        let resultObs = this.httpClient.get<T>(url).pipe(
            map(response => this.convertDateStringsToDates(response)),
            share()
        );

        return resultObs;
    }

    
    deleteOffer<T>(type: string, id: string, isLoanOffer: boolean = false): any {

        let url = `${this.apiEndpoint}/${type}/${isLoanOffer ? "loan/" : ""}${id}`;
        let resultPromise = this.httpClient.delete(url).toPromise();

        return resultPromise;
    }

    deleteCreditOrder(id: string) {

        let url = `${this.apiEndpoint}/orders/${id}`;
        let resultPromise = this.httpClient.delete(url).toPromise();

        return resultPromise;
    }

    getProductConfig(id: string, excludeJson: boolean) {

        let url = `${this.apiEndpoint}/productconfig/${id}/${excludeJson}`;
        let result = this.httpClient.get<ProductConfigurationDto>(url).pipe(
            map(response => this.convertDateStringsToDates(response)),
            share()
        ).toPromise<ProductConfigurationDto>();

        return result;
    }


}
